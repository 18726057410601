<template>
  <el-table :headings="tableHeadings" :items="items">
    <!-- data column -->
    <template #createdAt="{ item }">
      {{ item.createdAt | formatDate(dateFormatConfig) }} - {{ item.createdAt | formatTime(timeFormatConfig) }}
    </template>

    <!-- amount column -->
    <template #amountInclVat="{ item }">
      kr. {{ item.amountInclVat | formaNumber(2) }}
    </template>

    <!-- vat column -->
    <template #vat="{ item }">
      kr. {{ item.vat | formaNumber(2) }}
    </template>

    <!-- status column -->
    <template #fulfilledAt="{ item }">
      {{ getStatus(item.fulfilledAt) }}
    </template>

    <!-- BOGFØRT column -->
    <template #postedAt="{ item }">
      {{ item.postedAt ? (item.postedAt | formatDate(dateFormatConfig)) : '-' }}
    </template>
  </el-table>
</template>

<script>
const ElTable = () => import('@/components/shared/ELTable')

export default {
  name: 'AdminTable',

  components: { ElTable },

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      tableHeadings: [
        { label: 'Data', value: 'createdAt' },
        { label: 'Order item', value: 'title' },
        { label: 'Order no.', value: 'orderName' },
        { label: 'Vendor', value: 'advertiser' },
        { label: 'qty', value: 'quantity' },
        { label: 'amt', value: 'amountInclVat' },
        { label: 'vat', value: 'vat' },
        { label: 'status', value: 'fulfilledAt' },
        { label: 'BOGFØRT', value: 'postedAt' }
      ]
    }
  },

  computed: {
    dateFormatConfig () {
      return { day: 'numeric', month: 'numeric', year: 'numeric' }
    },

    timeFormatConfig () {
      return { hour: 'numeric', minute: 'numeric', second: 'numeric' }
    }
  },

  methods: {
    getStatus (value) {
      return value ? 'Fulfilled' : 'Open'
    }
  }
}
</script>
